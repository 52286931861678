import { FC } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './DotRatings.module.scss'
import { getReviewsAverageDotsType } from 'src/core/Hotel/domain/getReviewsAverageDotsType'

interface Props {
  ratings?: string
  size?: 'small' | 'medium'
}

export const DotRatings: FC<Props> = ({ ratings, size = 'small' }) => {
  const averageDotsType = getReviewsAverageDotsType(ratings)

  return (
    <div
      className={classNames(
        styles.dotWrapper,
        size === 'medium' && styles.isMedium,
        size === 'small' && styles.isSmall,
      )}
    >
      {averageDotsType.map((dotType, i) => (
        <span
          aria-hidden
          key={i}
          className={classNames(
            styles.dot,
            styles[dotType],
            size === 'medium' && styles.isMedium,
            size === 'small' && styles.isSmall,
          )}
        ></span>
      ))}
    </div>
  )
}
